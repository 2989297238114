import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import Config from "../../../helpers/Config";
import { Link } from "react-router-dom";

const ReturnPolicy = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);
  return (
    <>
      <Helmet>
        <title>{siteTitle} - Return & Exchange Policy</title>
        <meta
          name="description"
          content={siteTitle + " - Return & Exchange Policy"}
        />
        <meta name="keywords" content="Return & Exchange Policy"></meta>
      </Helmet>
      <SiteBreadcrumb title="Return & Exchange Policy" parent="home" />
      <section className="login-page section-big-py-space bg-light">
        <Grid display="flex" justifyContent="center" container>
          <Grid sx={{ color: "#fff" }} item xs={11} lg={5}>
            <Box>
              <Typography sx={{ fontWeight: "500" }}>
                We continuously strive to ensure that we provide all our
                customers with an amazing online shopping experience. If for any
                reason you are not satisfied with your purchase, you may request
                a refund or an exchange within 3 days from the date delivered.
                Please follow the instructions of our return & exchange policy
                below.
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography
                sx={{ mb: 2, fontWeight: "600", color: "#fff" }}
                variant="h6"
              >
                RETURN | EXCHANGE POLICY REQUIREMENTS
              </Typography>
              <Typography sx={{ fontWeight: "500" }}>
                Items must be neatly packed and ready securely in the
                re-sealable package you received them in.
                <br />
                <br />
                The invoice should also be included inside the package.
                <br />
                <br />
                Items must not be worn, altered, damaged, or washed.
                <br />
                <br />
                Items must have all tags attached.
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography
                sx={{ mb: 2, fontWeight: "600", color: "#fff" }}
                variant="h6"
              >
                RETURN | EXCHANGE PROCEDURE
              </Typography>
              <Typography sx={{ fontWeight: "500" }}>
                Please fill in and submit the Service Request Form on this page
                to initiate your request. We will contact you if further
                clarification is required.
                <br />
                <br />
                In most cases, exchange requests will be dispatched within 2
                working days depending on the stock availability. Returning
                items will be collected at the time of your new delivery. A
                collection & re-delivery charge of Rs.200-400/- may apply.
                Kindly note that this process will take 3-7 working days to be
                fulfilled.
                <br />
                <br />
                Returning items for refund can either be collected via the same
                courier service at your request or you may use a reliable
                courier to send them back to us. Refunds will be processed after
                inspection of returned items. The total value of the order will
                be refunded except for delivery charges. Transfers can be made
                to any nominated bank by you for the COD customers and for
                credit/debit card customers we will initiate a refund to your
                original method of payment. A notification with proof will be
                sent when the transfer is been made. This usually takes 2
                working days upon receiving the returned package.
                <br />
                <br />
                If any returns do not meet policy requirements, the items will
                be sent back to you immediately. We reserve the right to refuse
                a refund if the items have any signs of wear, alterations,
                misuse or damage.
                <br />
                <br />
                <a href="mailto:sales@alevelclothing.lk">
                  sales@alevelclothing.lk
                </a>
                <br />
                <br />
                We are pleased to serve you.
              </Typography>
            </Box>
          </Grid>
          <Grid sx={{ pl: { xs: 0, lg: 5 } }} item xs={11} lg={5}>
            <Typography
              sx={{ mb: 2, fontWeight: "600", color: "#fff" }}
              variant="h6"
            >
              Service Request Form
            </Typography>
            <Box>
              <Typography sx={{ mt: 2, color: "#fff" }}>Your Name</Typography>
              <TextField
                sx={{ mt: 1, border: "1px solid #fff" }}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box>
              <Typography sx={{ mt: 2, color: "#fff" }}>
                Your Contact No
              </Typography>
              <TextField
                sx={{ mt: 1, border: "1px solid #fff" }}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box>
              <Typography sx={{ mt: 2, color: "#fff" }}>Your Email</Typography>
              <TextField
                sx={{ mt: 1, border: "1px solid #fff" }}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box>
              <Typography sx={{ mt: 2, color: "#fff" }}>
                Order Number
              </Typography>
              <TextField
                sx={{ mt: 1, border: "1px solid #fff" }}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box>
              <Typography sx={{ mt: 2, color: "#fff" }}>
                Required Service
              </Typography>
              <TextField
                sx={{ mt: 1, border: "1px solid #fff" }}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box>
              <Typography sx={{ mt: 2, color: "#fff" }}>
                Your Message
              </Typography>
              <TextField
                multiline
                rows={4}
                sx={{ mt: 2, border: "1px solid #fff" }}
                variant="outlined"
                fullWidth
              />
            </Box>

            <Button size="large" sx={{ mt: 2 }} fullWidth variant="contained">
              Submit
            </Button>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default ReturnPolicy;
