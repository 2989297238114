// CustomModal.js
import React from 'react';
import './CustomModal.css'; 

const CustomModal = ({ isOpen, onConfirm, onCancel, message }) => {
  if (!isOpen) return null;

  return (
    <div className="custom-modal">
      <div className="custom-modal-content">
        <p>{message}</p>
        <button className='btn yes' onClick={onConfirm}>Yes</button>
        <button className='btn no' onClick={onCancel}>No</button>
      </div>
    </div>
  );
};

export default CustomModal;
