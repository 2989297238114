import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import "./Spinner.css";
import Gif from "../../../resources/gif.gif";
import Arrow from "../../../resources/arrow.png";
import { useDispatch, useSelector } from "react-redux";
import { getLanguageCodeFromSession } from "../../../helpers/CommonHelper";
import { useNavigate } from "react-router-dom";
import Config from "../../../helpers/Config";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import rootAction from "../../../stateManagment/actions/rootAction";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const Spinner = ({ cartItems, total }) => {
  const dispatch = useDispatch();
  const [isSpinning, setIsSpinning] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [count, setCount] = useState(0);
  const [doubled, setDoubled] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [stoppedSector, setStoppedSector] = useState(null);
  const loginUserDataJson = useSelector((state) => state.userReducer.user);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");
  const [highlightedSectorId, setHighlightedSectorId] = useState(null);
  const [discountRate, setDiscountRate] = useState("");
  const navigate = useNavigate();
  const [sectors] = useState([
    { id: 1, font: 40, name: "2.5%", size: 30, color: "#663300", rotate: 15 },
    { id: 2, font: 40, name: "5%", size: 30, color: "#0000ff", rotate: 45 },
    { id: 3, font: 40, name: "10%", size: 30, color: "#00ffff", rotate: 75 },
    { id: 4, font: 40, name: "15%", size: 30, color: "#9999ff", rotate: 105 },
    {
      id: 5,
      font: 25,
      name: "Buy 2 Get 1",
      size: 30,
      color: "#99ff99",
      rotate: 135,
    },
    { id: 6, font: 40, name: "25%", size: 30, color: "#ff0000", rotate: 160 },
    {
      id: 7,
      font: 25,
      name: "Buy 1 Get 1",
      size: 30,
      color: "#ff3399",
      rotate: 195,
    },
    { id: 8, font: 40, name: "50%", size: 30, color: "#ff6600", rotate: 225 },
    { id: 9, font: 40, name: "30%", size: 30, color: "#b3b3b3", rotate: 255 },
    { id: 10, font: 40, name: "100%", size: 30, color: "#ff6666", rotate: 285 },
    {
      id: 11,
      font: 25,
      name: "Buy 3 Get 1",
      size: 30,
      color: "#003333",
      rotate: 315,
    },
    { id: 12, font: 40, name: "20%", size: 30, color: "#ffff00", rotate: 345 },
  ]);
  const [discount, setDiscount] = useState({});
  const totalDegrees = sectors.reduce(
    (total, sector) => total + sector.size,
    0
  );
  const spinDuration = 5000;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const startSpin = () => {
    if (!loginUser || !loginUser.UserID || loginUser.UserID < 1) {
      navigate("/" + getLanguageCodeFromSession() + "/login");
    } else {
      setIsSpinning(true);
    setHighlightedSectorId(null);

    var wheel = document.getElementById('wheel');
    var randomDegree = Math.floor(Math.random() * 360) + 1080; 
    wheel.style.transition = 'transform 4s ease-out'; 
    wheel.style.transform = 'rotate(' + randomDegree + 'deg)';
    const spinDuration = 4000; 

    setTimeout(() => {
      setIsSpinning(false);
      const normalizedRotation = randomDegree % 360;
      const stoppedSector = determineStoppedSector(normalizedRotation);
      setStoppedSector(stoppedSector);
      GetDiscount();
      setHighlightedSectorId(stoppedSector.id);
    }, spinDuration);
    }
  };

  const halfDiscount = () => {
    if (discount.DiscountSpinName) {
      let discountString = discount.DiscountSpinName.replace("%", "");
      let discountValue = parseFloat(discountString);
      let halfDiscount = discountValue / 2;
      return `${halfDiscount}%`;
    }
    return "0%";
  };

  const ShareWithWhatsapp = () => {
    setCount((prevCount) => prevCount + 5);
    const shareUrl = `https://alevelclothing.lk/`;
    const shareMessage = `Hey, I just got a discount! Check out this shop: ${shareUrl}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      shareMessage
    )}`;
    window.open(whatsappUrl, "_blank");

    const discountMappings = {
      "2.5% Discount": { count: 1, rate: 0.025 },
      "5% Discount": { count: 1, rate: 0.05 },
      "10% Discount": { count: 1, rate: 0.1 },
      "15% Discount": { count: 1, rate: 0.15 },
      "25% Discount": { count: 2, rate: 0.25 },
    };

    if (["Buy 1 Get 1", "Buy 2 Get 1", "Buy 3 Get 1"].includes(discountRate)) {
      if (count + 1 === 6 && discountRate === "Buy 3 Get 1") {
        setDiscountRate("Buy 2 Get 1");
      }
      if (count + 1 === 10 && discountRate === "Buy 2 Get 1") {
        setDiscountRate("Buy 1 Get 1");
      }
    } else {
      const mapping = discountMappings[discountRate];
      if (mapping && count + 1 === mapping.count) {
        let totalDiscount = mapping.rate * 2;
        const d = (totalDiscount * 100).toFixed(1) + "% Discount";
        setDiscountRate(d);
        setDoubled(true);
        CountDown();
      }
    }
  };

  const CountDown = () => {
    const countdownValues = {
      "2.5% Discount": [1],
      "5% Discount": [2, 1],
      "10% Discount": [3, 2, 1],
      "15% Discount": [4, 3, 2, 1],
      "25% Discount": [6, 5, 4, 3, 2, 1],
      "Buy 3 Get 1": [5, 4, 3, 2, 1],
      "Buy 2 Get 1": [9, 8, 7, 6, 5, 4, 3, 2, 1],
    };

    if (countdownValues[discountRate]) {
      const values = countdownValues[discountRate];
      return values[count - 5] || undefined;
    }

    return undefined;
  };

  const Greaterthan = () => {
    switch (discountRate) {
      case "2.5% Discount":
        return 2;
      case "5% Discount":
        return 3;
      case "10% Discount":
        return 4;
      case "15% Discount":
        return 5;
      case "25% Discount":
        return 7;
      case "Buy 2 Get 1":
        return 10;
      case "Buy 3 Get 1":
        return 6;
      default:
        return 0;
    }
  };

  const determineStoppedSector = (rotation) => {
    let currentRotation = 0;
    for (const sector of sectors) {
      currentRotation += sector.size;
      if (rotation < currentRotation) {
        return sector;
      }
    }
    return sectors[0];
  };

  const DiscountAmount = async () => {
    const a = halfDiscount();
    let b = parseFloat(a) / 100;
    let c = total * b;
    setDiscountAmount(c);
  };

  const GetDiscount = async () => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    try {
      const paramCart = {
        requestParameters: {
          cartJsonData: JSON.stringify(cartItems),
          recordValueJson: "[]",
        },
      };

      const discountResponse = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_DISCOUNT"],
        Config["COMMON_CONTROLLER_SUB_URL"],
        paramCart,
        headers,
        "POST",
        true
      );

      if (discountResponse && discountResponse.data) {
        const discountData = JSON.parse(discountResponse.data.data);
        setDiscount(discountData);
      }
    } catch (error) {
      console.error("Error fetching discount:", error);
    }
  };

  const ClaimDiscount = async () => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const sendingresult = discountRate.replace(" Discount", "");
    try {
      const paramCart = {
        requestParameters: {
          Userid: loginUser.UserID,
          DiscountName: count >= 2 ? sendingresult : halfDiscount(),
          DiscountAmount: discountAmount,
          SpinWheelId: discount.Id,
        },
      };
      const claimResponse = await MakeApiCallAsync(
        Config.END_POINT_NAMES["POST_CLAIMED_DISCOUNT"],
        Config["COMMON_CONTROLLER_SUB_URL"],
        paramCart,
        headers,
        "POST",
        true
      );

      if (claimResponse && claimResponse.data) {
        const claimData = JSON.parse(claimResponse.data.data);
        loginUser.IsSpinDiscountClaimed = true;
        loginUser.DiscountAmount = 0;
        loginUser.DiscountName = count >= 2 ? sendingresult : halfDiscount();
        localStorage.setItem("user", JSON.stringify(loginUser));
        dispatch(rootAction.userAction.setUser(JSON.stringify(loginUser)));
        localStorage.setItem("isDoubled", doubled);
      }
    } catch (error) {
      console.error("Error claiming discount:", error);
    }
  };

  useEffect(() => {
    DiscountAmount();
    if (isSpinning) {
      const wheel = document.querySelector(".wheel");
      if (wheel) {
        wheel.style.transition = `transform ${spinDuration / 1000}s ease-out`;
        wheel.style.transform = `rotate(${rotation}deg)`;
      }
    }
  }, [rotation, isSpinning]);

  useEffect(() => {
    if (discount.DiscountSpinName) {
      SetDiscountName();
    }
  }, [discount]);

  const SetDiscountName = () => {
    const x = ["Buy 1 Get 1", "Buy 2 Get 1", "Buy 3 Get 1"].includes(
      discount.DiscountSpinName
    )
      ? discount.DiscountSpinName
      : halfDiscount();

    const y = ["Buy 1 Get 1", "Buy 2 Get 1", "Buy 3 Get 1"].includes(
      discount.DiscountSpinName
    )
      ? "Free"
      : "Discount";

    setDiscountRate(x + " " + y);
  };

  const closeModal = () => {
    ClaimDiscount();
    setStoppedSector(null);
  };
  const closeModalWithoutClaim = () => {
    setStoppedSector(null);
    window.location.reload();
  };

  return (
    <>{/* <div></div> */}
      {/* <div className="ar-image">
        
        <img width={isMobile ? "50px" : "100px"} src={Arrow} />
      </div> */}

      <div className="spinner-container">
        <div>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 730 730"
            className={`wheel`}
          >
            <defs>
              <filter id="glow">
                <feGaussianBlur stdDeviation="3.5" result="coloredBlur" />
                <feMerge>
                  <feMergeNode in="coloredBlur" />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>
            </defs>
            <g className="wheel">
              <circle className="frame" cx="365" cy="365" r="347.6" />
              {sectors.map((sector, index) => {
                const startAngle = sectors
                  .slice(0, index)
                  .reduce((total, s) => total + s.size, 0);
                const endAngle = startAngle + sector.size;
                const x1 = 365 + 347.6 * Math.cos((Math.PI * startAngle) / 180);
                const y1 = 365 + 347.6 * Math.sin((Math.PI * startAngle) / 180);
                const x2 = 365 + 347.6 * Math.cos((Math.PI * endAngle) / 180);
                const y2 = 365 + 347.6 * Math.sin((Math.PI * endAngle) / 180);
                const isHighlighted = highlightedSectorId === sector.id;
                const textX =
                  365 +
                  270 *
                    Math.cos((Math.PI * (startAngle + sector.size / 2)) / 180);
                const textY =
                  365 +
                  270 *
                    Math.sin((Math.PI * (startAngle + sector.size / 2)) / 180);
                return (
                  <g key={sector.id}>
                    <path
                      d={`M365,365 L${x1},${y1} A347.6,347.6 0 ${
                        sector.size > 180 ? 1 : 0
                      },1 ${x2},${y2} Z`}
                      fill={sector.color}
                      style={{ filter: "url(#glow)" }}
                      className="shining"
                    />
                    <text
                      x={textX}
                      y={textY}
                      fill="#fff"
                      textAnchor="end"
                      dominantBaseline="middle"
                      fontSize={sector.font}
                      transform={`rotate(${sector.rotate}, ${textX}, ${textY})`}
                    >
                      {sector.name}
                    </text>
                  </g>
                );
              })}
              <circle cx="365" cy="365" r="40.5" fill="#fff" />
              <circle cx="365" cy="365" r="11.6" fill="#000" />
            </g>
          </svg> */}
          <div id="wheel">
            <img
              width={isMobile ? "350px" : "600px"}
              src="https://tmadocumnetbucket.s3.ap-northeast-1.amazonaws.com/ALevelTshirts/PaymentWindow/SpinDiscount.png"
            />
          </div>
          <div className="button-container">
            <center>
              <button className="btn start" onClick={startSpin}>
                Spin the Wheel
              </button>
            </center>
          </div>
        </div>
      </div>
      <Modal
        open={!!stoppedSector}
        aria-labelledby="modal-title"
        onClose={closeModalWithoutClaim}
        aria-describedby="modal-description"
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.9)",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height: 500,
            background: "transparent",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            align="center"
            sx={{
              color: "#8e8e3e",
              textShadow: "2px 2px #515125",
              textTransform: "uppercase",
              letterSpacing: "2px",
              fontSize: { lg: "40px", xs: "20px" },
            }}
            id="modal-title"
          >
            Congratulations!
          </Typography>

          <Typography
            sx={{
              mt: 2,
              color: "white",
              textShadow: "2px 2px red",
              textTransform: "uppercase",
              letterSpacing: "2px",
              fontSize: { lg: "60px", xs: "30px" },
            }}
            align="center"
            id="modal-description"
          >
            {discountRate}
          </Typography>
          <center>
            <img src={Gif} width="150px" alt="Discount Gif" />
            <br />
            <Typography
              sx={{
                mt: 1,
                mb: 2,
                color: "#1A5319",
                textShadow: "1px 1px #508D4E",
                textTransform: "uppercase",
                letterSpacing: "2px",
                fontSize: { lg: "20px", xs: "15px" },
              }}
            >
              X2 Your Discount!
            </Typography>
            {count > 0 && count <= Greaterthan() ? (
              <Typography sx={{ color: "yellow", mb: 1 }}>
                Remaining Shares - {CountDown()}
              </Typography>
            ) : null}

            <button className="btn share" onClick={ShareWithWhatsapp}>
              <WhatsAppIcon /> Share Now
            </button>
            <br />
            <button className="btn claim" onClick={closeModal}>
              Claim Discount
            </button>
          </center>
        </Box>
      </Modal>
    </>
  );
};

export default Spinner;
