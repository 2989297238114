import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Row, Col, Carousel } from "reactstrap";
import { Link } from "react-router-dom";
import Config from "../../../helpers/Config";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import Banner1 from "../../../resources/banner/1-banner.png";
import Banner2 from "../../../resources/banner/2-banner.png";
import Banner3 from "../../../resources/banner/3-banner.png";
import Banner4 from "../../../resources/banner/4-banner.png";
import Banner5 from "../../../resources/banner/5-banner.png";
import Carousel1 from "../../../resources/banner/Carasal1.jpg";
import Carousel2 from "../../../resources/banner/Carousel2.jpg";
import Carousel3 from "../../../resources/banner/Carousel4.jpg";
import Carousel4 from "../../../resources/banner/Carousel3.jpg";

const settings = {
  autoplay: true,
  autoplaySpeed: 5000,
};

const BannerSlider = () => {
  const [elemOne, setElemOne] = useState({});
  const [bannerList, setBannerList] = useState([]);
  const [adminPanelBaseURL] = useState(Config["ADMIN_BASE_URL"]);

  const handleBannerButtonClickUrl = (url) => {
    if (url) {
      // window.location.href = url;
    } else {
      return false;
    }
  };

  const onMouseHover = (e) => {
    const pageX = e.clientX - window.innerWidth / 2;
    const pageY = e.clientY - window.innerHeight / 2;
    setElemOne({
      transform: `translateX(${7 + pageX / 150}%) translateY(${1 + pageY / 150}%)`,
    });
  };

  const banners = [
    {
      img: Carousel1,
      titleone: "Timeless and Trendy",
      titletwo: "Sleek and Sophisticated",
      titlethree: "Effortlessly Cool: The Essential Black T-Shirt",
    },
    {
      img: Carousel2,
      titleone: "Stand Out in Style",
      titletwo: "Feel the Energy",
      titlethree: "Passion and Power: The Ultimate Red T-Shirt",
    },
    {
      img: Carousel3,
      titleone: "Cool and Classic",
      titletwo: "Stay Stylish",
      titlethree: "Effortless Elegance: The Ultimate Blue T-Shirt",
    },
    {
      img: Carousel4,
      titleone: "Regal and Radiant",
      titletwo: "Express Yourself",
      titlethree: "Vibrant Vibes: The Ultimate Purple T-Shirt",
    },
  ];

  useEffect(() => {
    const dataOperationFunc = async () => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const param = {
        requestParameters: {
          recordValueJson: "[]",
        },
      };

      const response = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_HOME_SCREEN_BANNER"],
        null,
        param,
        headers,
        "POST",
        true
      );

      if (response && response.data) {
        setBannerList(JSON.parse(response.data.data));
      }
    };

    dataOperationFunc().catch(console.error);
  }, []);

  return (
    <section className="theme-slider b-g-white" id="theme-slider">
      <div className="custom-container">
        <Row>
          <Col>
            <div className="slide-1 no-arrow">
              <Slider {...settings}>
                {banners.map((banner, index) => (
                  <div
                    key={index}
                    className="slider-banner slide-banner-4"
                    onMouseMove={(e) => onMouseHover(e)}
                  >
                    {/* <div className="slider-img home-banner-bg">
                      <ul className="layout5-slide-1">
                        <li style={{ ...elemOne, display: 'flex', justifyContent: 'end' }} id="img-1">
                          <img
                            src={banner.img}
                            className="img-fluid banner-img-home"
                            alt="slider"
                          />
                        </li>
                        
                      </ul>
                    </div>
                    <div className="slider-banner-contain">
                      <div>
                        <h3 className="home-top-title">{banner.titleone}</h3>
                        <h1>{banner.titletwo}</h1>
                        <h2>{banner.titlethree}</h2>
                        <Link
                          className="btn home-btn-banner-left"
                          onClick={(e) => {
                            e.preventDefault();
                            handleBannerButtonClickUrl(1);
                          }}
                        >
                          View More
                        </Link>
                      </div>
                    </div> */}
                    <img width="100%" src={banner.img}/>
                  </div>
                ))}
              </Slider>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default BannerSlider;
