import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import Config from "../../../helpers/Config";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);
  return (
    <>
      <Helmet>
        <title>{siteTitle} - Privacy Policy</title>
        <meta name="description" content={siteTitle + " - Privacy Policy"} />
        <meta name="keywords" content="Privacy Policy"></meta>
      </Helmet>
      <SiteBreadcrumb title="Privacy Policy" parent="home" />
      <section className="login-page section-big-py-space bg-light">
        <Grid display="flex" justifyContent="center" container>
          <Grid sx={{color: '#fff'}} item xs={11} lg={10}>
            <Box>
              <Typography
                sx={{ mb: 2, fontWeight: "600", color: "#fff" }}
                variant="h6"
              >
                SECTION 1 | WHAT DO WE DO WITH YOUR INFORMATION?
              </Typography>
              <Typography sx={{ fontWeight: "500" }}>
                When you purchase something from our store, as part of the
                buying and selling process, we collect the personal information
                you give us such as your name, address and email address.
                <br /><br />
                When you browse our store, we also automatically receive your
                computer’s internet protocol (IP) address in order to provide us
                with information that helps us learn about your browser and
                operating system.
                <br /><br />
                Email marketing : With your permission, we may send you emails
                about our store, new products and other updates.
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography
                sx={{ mb: 2, fontWeight: "600", color: "#fff" }}
                variant="h6"
              >
                SECTION 2 | CONSENT
              </Typography>
              <Typography sx={{ mb: 2, fontWeight: "600", color: "#fff" }}>
                How do you get my consent?
              </Typography>
              <Typography sx={{ fontWeight: "500" }}>
                When you provide us with personal information to complete a
                transaction, verify your credit card, place an order, arrange
                for a delivery or return a purchase, we imply that you consent
                to our collecting it and using it for that specific reason only.
                <br /><br />
                If we ask for your personal information for a secondary reason,
                like marketing, we will either ask you directly for your
                expressed consent, or provide you with an opportunity to say no
              </Typography>
              <Typography
                sx={{ mb: 2, mt: 2, fontWeight: "600", color: "#fff" }}
              >
                How do I withdraw my consent?
              </Typography>
              <Typography sx={{ fontWeight: "500" }}>
                If after you opt-in, you change your mind, you may withdraw your
                consent for us to contact you, for the continued collection, use
                or disclosure of your information, at anytime, by contacting us
                at <a href="">sales@alevels.lk</a>
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography
                sx={{ mb: 2, fontWeight: "600", color: "#fff" }}
                variant="h6"
              >
                SECTION 3 | DISCLOSURE
              </Typography>
              <Typography sx={{ fontWeight: "500" }}>
                We may disclose your personal information if we are required by
                law to do so or if you violate our{" "}
                <Link to="/terms-of-services">Terms of Service</Link>.
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography
                sx={{ mb: 2, fontWeight: "600", color: "#fff" }}
                variant="h6"
              >
                SECTION 4 | WOOCOMMERCE & GENIE
              </Typography>
              <Typography sx={{ fontWeight: "500" }}>
                Our store is hosted on <a href="">WooCommerce</a>. They provide
                us with the online e-commerce platform that allows us to sell
                our products and services to you.
                <br /><br />
                Your data is stored through WooCommerce’s data storage,
                databases and the general WooCommerce application. They store
                your data on a secure server behind a firewall.
                <br /><br />
                Online payments are handled by <a href="">Genie</a> powered by
                Dialog.
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography
                sx={{ mb: 2, fontWeight: "600", color: "#fff" }}
                variant="h6"
              >
                SECTION 5 | THIRD-PARTY SERVICES
              </Typography>
              <Typography sx={{ fontWeight: "500" }}>
                In general, the third-party providers used by us will only
                collect, use and disclose your information to the extent
                necessary to allow them to perform the services they provide to
                us.
                <br /><br />
                However, certain third-party service providers, such as payment
                gateways and other payment transaction processors, have their
                own privacy policies in respect to the information we are
                required to provide to them for your purchase-related
                transactions.
                <br /><br />
                For these providers, we recommend that you read their privacy
                policies so you can understand the manner in which your personal
                information will be handled by these providers.
                <br /><br />
                In particular, remember that certain providers may be located in
                or have facilities that are located in a different jurisdiction
                than either you or us. So if you elect to proceed with a
                transaction that involves the services of a third-party service
                provider, then your information may become subject to the laws
                of the jurisdiction(s) in which that service provider or its
                facilities are located.
                <br /><br />
                Once you leave our store’s website or are redirected to a
                third-party website or application, you are no longer governed
                by this Privacy Policy or our website’s Terms of Service.
              </Typography>
              <Typography sx={{ mb: 2,mt:2, fontWeight: "600", color: "#fff" }}>
                LINKS
              </Typography>
              <Typography sx={{ fontWeight: "500" }}>
                When you click on links on our store, they may direct you away
                from our site. We are not responsible for the privacy practices
                of other sites and encourage you to read their privacy
                statements.
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography
                sx={{ mb: 2, fontWeight: "600", color: "#fff" }}
                variant="h6"
              >
                SECTION 6 | SECURITY
              </Typography>
              <Typography sx={{ fontWeight: "500" }}>
                To protect your personal information, we take reasonable
                precautions and follow industry best practices to make sure it
                is not inappropriately lost, misused, accessed, disclosed,
                altered or destroyed.
              </Typography>
              <Typography sx={{ mb: 2,mt:2, fontWeight: "600", color: "#fff" }}>
                COOKIES
              </Typography>
              <Typography sx={{ fontWeight: "500" }}>
                To keep track of cart data, WooCommerce makes use of 3 cookies:
                <br /><br />
                woocommerce_cart_hash
                <br />
                woocommerce_items_in_cart
                <br />
                wpwoocommerce_session
                <br /><br />
                The first two cookies contain information about the cart as a
                whole and helps WooCommerce know when the cart data changes. The
                final cookie (wpwoocommerce_session) contains a unique code for
                each customer so that it knows where to find the cart data in
                the database for each customer. No personal information is
                stored within these cookies.
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography
                sx={{ mb: 2, fontWeight: "600", color: "#fff" }}
                variant="h6"
              >
                SECTION 7 | AGE OF CONSENT
              </Typography>
              <Typography sx={{ fontWeight: "500" }}>
                By using this site, you represent that you are at least the age
                of majority in your state or province of residence, or that you
                are the age of majority in your state or province of residence
                and you have given us your consent to allow any of your minor
                dependents to use this site.
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography
                sx={{ mb: 2, fontWeight: "600", color: "#fff" }}
                variant="h6"
              >
                SECTION 8 | CHANGES TO THIS PRIVACY POLICY
              </Typography>
              <Typography sx={{ fontWeight: "500" }}>
                We reserve the right to modify this privacy policy at any time,
                so please review it frequently. Changes and clarifications will
                take effect immediately upon their posting on the website. If we
                make material changes to this policy, we will notify you here
                that it has been updated, so that you are aware of what
                information we collect, how we use it, and under what
                circumstances, if any, we use and/or disclose it.
                <br /><br />
                If our store is acquired or merged with another company, your
                information may be transferred to the new owners so that we may
                continue to sell products to you.
              </Typography>
              <Typography sx={{ mb: 2,mt:2, fontWeight: "600", color: "#fff" }}>
                QUESTIONS AND CONTACT INFORMATION
              </Typography>
              <Typography sx={{ fontWeight: "500" }}>
                If you would like to: access, correct, amend or delete any
                personal information we have about you, register a complaint, or
                simply want more information, please contact us.
                <br /><br />
                Email : <a href="mailto:sales@alevelclothing.lk">sales@alevelclothing.lk</a>
                <br />
                Office : <a href='tel:+94 717 345 543'> +94 717 345 543</a>
                <br />
                <br />
                A Level Clothing,<br/>No: 192, Polwatta Rd,<br/>Pamunuwa, Maharagama,<br/>Sri Lanka
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default PrivacyPolicy;
