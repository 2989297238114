import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import BestFacilities from "../../components/shared/BestFacilities";
import { useSelector, useDispatch } from "react-redux";
import { LOADER_DURATION } from "../../../helpers/Constants";
import { v4 as uuidv4 } from "uuid";
import CryptoJS from "crypto-js";
import {
  showErrorMsg,
  showInfoMsg,
  showSuccessMsg,
  validateAnyFormField,
} from "../../../helpers/ValidationHelper";
import {
  MakeApiCallSynchronous,
  MakeApiCallAsync,
  GetLoadSTRPPublishable,
  MakeApiCallCheckoutAsync,
} from "../../../helpers/ApiHelpers";
import Config from "../../../helpers/Config";
import rootAction from "../../../stateManagment/actions/rootAction";
import OrderSummary from "../../components/cart/OrderSummary";
import {
  makePriceRoundToTwoPlaces,
  makeProductShortDescription,
} from "../../../helpers/ConversionHelper";
import { Helmet } from "react-helmet";
import {
  Input,
  Label,
  Form,
  Row,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  Button,
} from "reactstrap";

//--strip
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutStripForm from "../../components/cart/CheckoutStripForm";
import {
  GetDefaultCurrencyCode,
  GetDefaultCurrencySymbol,
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";

//--Paypal
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import GlobalEnums from "../../../helpers/GlobalEnums";
import Spinner from "./Spinner";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import CustomModal from "./CustomModal";

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRP_PUBLISHABLE_KEY);

const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    script.onload = () => resolve(script);
    script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
    document.body.appendChild(script);
  });
};

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const stripe = useStripe();
  // const elements = useElements();
  const [showCardSectionStripe, setshowCardSectionStripe] = useState(false);
  const [view, setView] = useState(false);
  const [showCardSectionPaypal, setshowCardSectionPaypal] = useState(false);
  const [PaymentMethod, setPaymentMethod] = useState(
    process.env.REACT_APP_STRIPE_PAYMENT_METHOD ?? 5
  );
  const [count, setCount] = useState(0);
  const [discountRate, setDiscountRate] = useState("");
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);
  const [OrderNote, setOrderNote] = useState("");
  const [isDiscount, setIsDiscount] = useState(false);
  const [CartSubTotal, setCartSubTotal] = useState(0);
  const [totalqty, setTotalqty] = useState(0);
  const [ShippingSubTotal, setShippingSubTotal] = useState(0);
  const [OrderTotal, setOrderTotal] = useState(0);
  const [OrderTotalAfterDiscount, setOrderTotalAfterDiscount] = useState(0);
  const [cartProductsData, setCartProductsData] = useState(0);
  const [CouponCode, setCouponCode] = useState("");
  const [IsCouponCodeApplied, setIsCouponCodeApplied] = useState(false);
  const [spinOpen, setSpinOpen] = useState(false);
  const [IsAlreadyDiscountApplied, setIsAlreadyDiscountApplied] =
    useState(false);
  const [CouponCodeCssClass, setCouponCodeCssClass] =
    useState("cart-coupon-code");
  const [paypalOrderID, setPaypalOrderID] = useState(false);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const loginUserDataJson = useSelector((state) => state.userReducer.user);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");
  const IsDoubled = localStorage.getItem("isDoubled");
  const cartJsonDataSession = useSelector(
    (state) => state.cartReducer.cartItems
  );
  const cartItemsSession = JSON.parse(cartJsonDataSession ?? "[]");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // State for manual address fields
  const [manualAddress, setManualAddress] = useState({
    address: '',
    city: '',
    state: '',
    zip: '',
    country: loginUser.CountryName,
  });
  const [useManualAddress, setUseManualAddress] = useState(false);

  useEffect(() => {
    LoadCitiesData();
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const LoadCitiesData = async () => {
    let headersCity = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    let paramCity = {
      requestParameters: {
        StateProvinceId: loginUser.StateProvinceID,
        recordValueJson: "[]",
      },
    };

    //--Get cities list
    let responseCities = await MakeApiCallAsync(
      Config.END_POINT_NAMES["GET_CITIES_LIST"],
      null,
      paramCity,
      headersCity,
      "POST",
      true
    );

    if (responseCities != null && responseCities.data != null) {
      //alert(loginUser.CityID);
      const cities = responseCities.data;
      console.log(cities);
      // const filteredCities = cities.filter(
      //   (city) => city.CityID === loginUser.CityID
      // );
    }
  };
  // console.log(loginUser);

  const style = {
    backgroundImage:
      "url('https://www.payhere.lk/downloads/images/payhere_short_banner_dark.png')",
    width: "100%",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    height: isMobile ? "50px" : "100px",
  };
  if (
    loginUser == undefined ||
    loginUser.UserID == undefined ||
    loginUser.UserID < 1
  ) {
    navigate("/" + getLanguageCodeFromSession() + "/login");
  }

  if (
    cartItemsSession == undefined ||
    cartItemsSession == null ||
    cartItemsSession.length < 1
  ) {
    showInfoMsg("Your cart is empty");
    navigate("/" + getLanguageCodeFromSession() + "/");
  }

  const GetCouponCodeInfo = async () => {
    if (IsCouponCodeApplied) {
      showInfoMsg("Coupon code is already applied!");
      return false;
    }

    let isValid = validateAnyFormField(
      "Coupon Code",
      CouponCode,
      "text",
      null,
      30,
      true
    );
    if (isValid == false) {
      return false;
    }

    const headersCoupon = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const paramCoupon = {
      requestParameters: {
        CouponCode: CouponCode,
        cartJsonData: JSON.stringify(cartItemsSession),
      },
    };

    const couponResponse = await MakeApiCallAsync(
      Config.END_POINT_NAMES["GET_COUPON_CODE_DISCOUNT"],
      Config["COMMON_CONTROLLER_SUB_URL"],
      paramCoupon,
      headersCoupon,
      "POST",
      true
    );

    if (couponResponse != null && couponResponse.data != null) {
      let copounData = JSON.parse(couponResponse.data.data);
      // console.log(copounData);
      if (
        copounData != undefined &&
        copounData.DiscountValueAfterCouponAppliedWithQuantity != undefined &&
        copounData.DiscountValueAfterCouponAppliedWithQuantity > 0
      ) {
        setOrderTotalAfterDiscount(
          OrderTotal - copounData.DiscountValueAfterCouponAppliedWithQuantity ??
            0
        );
        setIsCouponCodeApplied(true);
      } else {
        showErrorMsg("Invalid coupon code!");
      }
    }
  };

  const handleCashonDelivery = () => {
    setPaymentMethod("6");
    // navigate("/" + getLanguageCodeFromSession() + "/checkout");
  };

  const handleCheckoutOnSubmit = async (e) => {
    try {
      e.preventDefault();

      //-- First Disable all forms
      setshowCardSectionStripe(false);
      setshowCardSectionPaypal(false);

      if (PaymentMethod === process.env.REACT_APP_STRIPE_PAYMENT_METHOD) {
        //setshowCardSectionStripe(true);
        paymentGateway();
      } else if (
        PaymentMethod === process.env.REACT_APP_PAYPAL_PAYMENT_METHOD
      ) {
        setshowCardSectionPaypal(true);
      } else if (
        PaymentMethod === process.env.REACT_APP_CASH_ON_DELIVERY_PAYMENT_METHOD
      ) {
        setIsModalOpen(true);
        // let isYes = window.confirm("Do you really want place order?");
        // if (isYes) {
        //   //--start loader
        //   dispatch(rootAction.commonAction.setLoading(true));

        //   PlaceAndConfirmCustomerOrder(null);

        //   //--stop loader
        //   setTimeout(() => {
        //     dispatch(rootAction.commonAction.setLoading(false));
        //   }, LOADER_DURATION);
        // }
      }
    } catch (err) {
      showErrorMsg("Internet Issue!. please log out and refresh site.");
      console.log(err.message);
      if (PaymentMethod === process.env.REACT_APP_STRIPE_PAYMENT_METHOD) {
        HandleStripCardModal();
        HandlePaypalCardModal();
      }

      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);
    }
  };

  const handleConfirm = () => {
    setIsModalOpen(false);
    dispatch(rootAction.commonAction.setLoading(true));

    //alert("1");
    PlaceAndConfirmCustomerOrder(null);
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const ShareWithWhatsapp = (rate) => {
    setCount((prevCount) => prevCount + 1);
    const shareUrl = `https://alevelclothing.lk/`;
    const shareMessage = `Hey, I just got a discount! Check out this shop: ${shareUrl}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      shareMessage
    )}`;
    window.open(whatsappUrl, "_blank");

    const discountMappings = {
      "2.5%": { count: 1, rate: 0.025 },
      "5%": { count: 1, rate: 0.05 },
      "10%": { count: 1, rate: 0.1 },
      "15%": { count: 1, rate: 0.15 },
      "25%": { count: 2, rate: 0.25 },
    };

    if (["Buy 1 Get 1", "Buy 2 Get 1", "Buy 3 Get 1"].includes(rate)) {
      alert(rate);
      if (count + 1 === 6 && rate === "Buy 3 Get 1") {
        setDiscountRate("Buy 2 Get 1");
        loginUser.IsSpinDiscountClaimed = true;
        loginUser.DiscountAmount = 0;
        loginUser.DiscountName = "Buy 2 Get 1";
        localStorage.setItem("user", JSON.stringify(loginUser));
        dispatch(rootAction.userAction.setUser(JSON.stringify(loginUser)));
        localStorage.setItem("isDoubled", true);
        window.location.reload();
      }
      if (count + 1 === 6 && rate === "Buy 2 Get 1") {
        setDiscountRate("Buy 1 Get 1");
        loginUser.IsSpinDiscountClaimed = true;
        loginUser.DiscountAmount = 0;
        loginUser.DiscountName = "Buy 1 Get 1";
        localStorage.setItem("user", JSON.stringify(loginUser));
        dispatch(rootAction.userAction.setUser(JSON.stringify(loginUser)));
        localStorage.setItem("isDoubled", true);
        window.location.reload();
      }
    } else {
      const mapping = discountMappings[rate];
      if (mapping && count + 1 === mapping.count) {
        let totalDiscount = mapping.rate * 2;
        const d = (totalDiscount * 100).toFixed(1) + "% Discount";
        setDiscountRate(d);
        loginUser.IsSpinDiscountClaimed = true;
        loginUser.DiscountAmount = 0;
        loginUser.DiscountName = d;
        localStorage.setItem("user", JSON.stringify(loginUser));
        dispatch(rootAction.userAction.setUser(JSON.stringify(loginUser)));
        localStorage.setItem("isDoubled", true);
        window.location.reload();
      }
    }
  };

  const CountDown = () => {
    const countdownValues = {
      "2.5%": [1],
      "5%": [2, 1],
      "10%": [3, 2, 1],
      "15%": [4, 3, 2, 1],
      "25%": [6, 5, 4, 3, 2, 1],
      "Buy 3 Get 1": [5, 4, 3, 2, 1],
      "Buy 2 Get 1": [9, 8, 7, 6, 5, 4, 3, 2, 1],
    };

    if (countdownValues[loginUser.DiscountName]) {
      const values = countdownValues[loginUser.DiscountName];
      return values[count - 1] || undefined;
    }

    return undefined;
  };

  const Greaterthan = () => {
    switch (loginUser.DiscountName) {
      case "2.5%":
        return 2;
      case "5%":
        return 3;
      case "10%":
        return 4;
      case "15%":
        return 5;
      case "25%":
        return 7;
      case "Buy 2 Get 1":
        return 10;
      case "Buy 3 Get 1":
        return 6;
      default:
        return 0;
    }
  };

  const PlaceAndConfirmCustomerOrder = async (
    StripPaymentToken,
    payPalOrderConfirmJson = "{}"
  ) => {
    try {
      const headersStrip = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const paramSrip = {
        requestParameters: {
          UserID: loginUser.UserID,
          OrderNote: OrderNote,
          cartJsonData: JSON.stringify(cartItemsSession),
          CouponCode: IsCouponCodeApplied == true ? CouponCode : "",
          PaymentMethod: PaymentMethod,
          paymentToken: StripPaymentToken ?? "",
          OrderTotal: 100,
          DiscountTotal: PaymentMethod == 5 ? calDiscount() : 0,
          payPalOrderConfirmJson: payPalOrderConfirmJson ?? "",
          recordValueJson: "[]",
          ManualAddress: useManualAddress ? manualAddress : null,
        },
      };

console.log("paramSrip");
console.log(paramSrip);

      const stripServerResponse = await MakeApiCallAsync(
        Config.END_POINT_NAMES["POST_CUSTOMER_ORDER"],
        Config["COMMON_CONTROLLER_SUB_URL"],
        paramSrip,
        headersStrip,
        "POST",
        true
      );

      console.log("stripServerResponse");
      console.log(stripServerResponse);
      if (
        stripServerResponse != null &&
        stripServerResponse.data != null &&
        stripServerResponse.status == 200
      ) {
        let stripServerResponseDetail = JSON.parse(
          stripServerResponse.data.data != undefined &&
            stripServerResponse.data.data != ""
            ? stripServerResponse.data.data
            : "[]"
        );

        console.log("stripServerResponseDetail");
        console.log(stripServerResponseDetail);

        if (
          stripServerResponseDetail.length > 0 &&
          stripServerResponseDetail[0].ResponseMsg != undefined &&
          stripServerResponseDetail[0].ResponseMsg ==
            "Order Placed Successfully"
        ) {
          showSuccessMsg("Order Placed Successfully!");
          setTimeout(async function () {
            //alert();
            navigate("/" + getLanguageCodeFromSession() + "/");
            dispatch(rootAction.cartAction.setCustomerCart("[]"));
            dispatch(rootAction.cartAction.SetTotalCartItems(0));
            localStorage.setItem("cartItems", "[]");
            loginUser.IsSpinDiscountClaimed = false;
            loginUser.DiscountAmount = 0;
            loginUser.DiscountName = "0%";
            localStorage.setItem("user", JSON.stringify(loginUser));
            dispatch(rootAction.userAction.setUser(JSON.stringify(loginUser)));
          }, 1000);
        } else {
          showErrorMsg("Internet Issue!. please log out and refresh site.");
        }
      } else {
        showErrorMsg("Internet Issue!. please log out and refresh site.");
      }

      if (PaymentMethod === process.env.REACT_APP_STRIPE_PAYMENT_METHOD) {
        // alert("CardPayment");
        //HandleStripCardModal();
      } else if (
        PaymentMethod === process.env.REACT_APP_PAYPAL_PAYMENT_METHOD
      ) {
        HandlePaypalCardModal();
      }
    } catch (err) {
      showErrorMsg("Internet Issue!. please log out and refresh site.");
      console.log(err.message);
      if (PaymentMethod === process.env.REACT_APP_STRIPE_PAYMENT_METHOD) {
        HandleStripCardModal();
      }

      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);
    }
  };

  const HandleStripCardModal = () => {
    setshowCardSectionStripe(!showCardSectionStripe);
  };

  const HandlePaypalCardModal = () => {
    setshowCardSectionPaypal(!showCardSectionPaypal);
  };

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Sunflower",
            amount: {
              //currency_code: "USD",
              currency_code: GetDefaultCurrencyCode(),
              value:
                OrderTotalAfterDiscount != undefined &&
                OrderTotalAfterDiscount > 0
                  ? OrderTotalAfterDiscount
                  : OrderTotal,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((paypalOrderID) => {
        setPaypalOrderID(paypalOrderID);
        return paypalOrderID;
      });
  };

  // check paypal Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      //setSuccess(true); replace with your own

      //-- Set paypal json response if approve
      let JsonDetail = JSON.stringify(details);

      //--start loader
      dispatch(rootAction.commonAction.setLoading(true));
//alert("2");
      setTimeout(() => {
        PlaceAndConfirmCustomerOrder(null, JsonDetail);
      }, 500);

      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);

      HandlePaypalCardModal();
    });
  };

  //capture likely error for paypal
  const onError = (data, actions) => {
    HandlePaypalCardModal();
    showErrorMsg("Internet Issue!. please log out and refresh site.");
  };

  const calDiscount = () => {
    const dis_percen = loginUser.DiscountName;
    // alert(dis_percen);
    if(dis_percen!="NaN%" && dis_percen!="Buy 2 Get 1 Free"){
      let discount_value = parseFloat(dis_percen) / 100;
      let discount_price = CartSubTotal * discount_value;
      return discount_price.toFixed(2);
    }else{
      
      return 0;
    }
    

    // if (claimed != null) {
    //   if (dis_value != 5 && dis_value != 7) {
    //     let discount_price;
    //     if (dis_value == 1) {
    //       discount_price = OrderTotal * 0.05;
    //     } else if (dis_value == 2) {
    //       discount_price = OrderTotal * 0.1;
    //     } else if (dis_value == 3) {
    //       discount_price = OrderTotal * 0.2;
    //     } else if (dis_value == 4) {
    //       discount_price = OrderTotal * 0.3;
    //     } else if (dis_value == 6) {
    //       discount_price = OrderTotal * 0.5;
    //     } else if (dis_value == 8) {
    //       discount_price = OrderTotal * 1;
    //     } else {
    //       discount_price = OrderTotal;
    //     }

    //     return (discount_price.toFixed(2));
    //   }
    // }
  };
  useEffect(() => {
    // declare the data fetching function
    const dataOperationInUseEffect = async () => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      //--Get cart data
      const paramCart = {
        requestParameters: {
          cartJsonData: JSON.stringify(cartItemsSession),
          recordValueJson: "[]",
        },
      };
      const customerCartResponse = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_CUSTOMER_CART_ITEMS"],
        Config["COMMON_CONTROLLER_SUB_URL"],
        paramCart,
        headers,
        "POST",
        true
      );
      if (customerCartResponse != null && customerCartResponse.data != null) {
        let finalData = JSON.parse(customerCartResponse.data.data);
        if (Array.isArray(finalData.productsData)) {
          let totalQuantity = finalData.productsData.reduce(
            (sum, product) => sum + product.Quantity,
            0
          );
          setTotalqty(totalQuantity);
        } else {
          console.error("finalData.productsData is not an array");
        }
        if (finalData != null) {
          setTimeout(() => {
            setCartProductsData(finalData.productsData);
            setCartSubTotal(finalData.cartSubTotal);
              setShippingSubTotal(finalData.shippingSubTotal);
              var AddHArdCodedShipTotal = finalData.cartSubTotal+350 ;
              setOrderTotal(AddHArdCodedShipTotal);

            if (
              finalData.productsData.length > 0 &&
              finalData.productsData.some((el) => el.DiscountedPrice > 0)
            ) {
              setIsAlreadyDiscountApplied(true);
            }
          }, 500);
        }
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    dataOperationInUseEffect().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, []);
  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {
      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["Checkout"],
        null
      );
      if (
        arryRespLocalization != null &&
        arryRespLocalization != undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    // call the function
    dataOperationFunc().catch(console.error);
  }, []);

  useEffect(() => {
    // Load the PayHere script
    loadScript("https://www.payhere.lk/lib/payhere.js")
      .then(() => {
        console.log("PayHere script loaded");
      })
      .catch((error) => {
        console.error(error);
      });

    // Cleanup function to remove the script when the component unmounts
    return () => {
      const script = document.querySelector(
        `script[src="https://www.payhere.lk/lib/payhere.js"]`
      );
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const handleSpinOpen = () => {
    setPaymentMethod("5");
    // const isLocal = localStorage.getItem("discount-value");
    // if(isLocal != null){
    // navigate("/" + getLanguageCodeFromSession() + "/checkout");
    // }
    // setSpinOpen(true);
  };

  const paymentGateway = async () => {
    if (!loginUser.IsSpinDiscountClaimed) {
      setSpinOpen(true);
    } else {
      if (loginUser.DiscountName === "Buy 2 Get 1" && totalqty != 2) {
        showInfoMsg(
          "To qualify for this discount, you need to purchase two items."
        );
      } else if (loginUser.DiscountName === "Buy 3 Get 1" && totalqty != 3) {
        showInfoMsg(
          "To qualify for this discount, you need to purchase three items."
        );
      } else {
        try {
          const final_total =
            loginUser.IsSpinDiscountClaimed &&
            loginUser.DiscountName != "Buy 1 Get 1" &&
            loginUser.DiscountName != "Buy 2 Get 1" &&
            loginUser.DiscountName != "Buy 3 Get 1"
              ? OrderTotal - calDiscount()
              : OrderTotal;
  
          const amount = final_total;
          const merchant_id = "235086";
          const order_id = uuidv4();
          //const merchant_secret ="MTkxNjgyNTkwMDIxNjEyNjU1MDUzNjkyNTgyODYzNTI3MjQwNTcx";//alevel sandbox
          //const merchant_secret ="Mjk3MzI4MDEzODI5ODE1NzY4MjUzOTEwNjQwNDgxMzcyNzk2Mjc1NA==";//local
          const merchant_secret ="MTA5MTgyMTk4NDE1NDUwMjI3NDgxNTEwOTkzMzI0MzQyNjQ2MjAyNw==";
          const currency = "LKR";
          const hashedSecret = CryptoJS.MD5(merchant_secret).toString().toUpperCase();
          const hash = CryptoJS.MD5(
            merchant_id +
              order_id +
              amount.toFixed(2) +
              currency +
              CryptoJS.MD5(merchant_secret).toString().toUpperCase()
          )
            .toString()
            .toUpperCase();
            const paymentStatus = true;

          window.payhere.onDismissed = function onDismissed() {
            console.log("Payment dismissed");
            paymentStatus=false;
          };
  
          window.payhere.onError = function onError(error) {
            console.log("Error: " + error);
            paymentStatus=false;
          };
  
          window.payhere.onCompleted = async function onCompleted(orderId) {
            console.log("Payment completed. OrderID: " + orderId);
            // Simulate checking payment status if needed, otherwise directly place the order
             // Assume true for successful payment status
             var statusCode=2;
             const localMd5Sig =  CryptoJS.MD5(merchant_id + order_id + amount.toFixed(2) + currency +statusCode+ hashedSecret).toString().toUpperCase();
            var databaseResponse="";

            const headers = {
              Accept: "application/json",
              "Content-Type": "application/json",
            };

            const paramCart = {
              requestParameters: {
                OrderId: JSON.stringify(order_id),
                recordValueJson: "[]",
              },
            };

            const paymentResponse = await MakeApiCallAsync(
              Config.END_POINT_NAMES["GET_PAYMENT"],
              Config["COMMON_CONTROLLER_SUB_URL"],
              paramCart,
              headers,
              "POST",
              true
            );

            if (paymentResponse && paymentResponse.data) {
              databaseResponse = JSON.parse(paymentResponse.data.data);
            }

            console.log(localMd5Sig);
            console.log(databaseResponse);
            if (localMd5Sig==databaseResponse.Md5Sig) {
              dispatch(rootAction.commonAction.setLoading(true));
              //alert();
              PlaceAndConfirmCustomerOrder(null);
              setTimeout(() => {
                dispatch(rootAction.commonAction.setLoading(false));
              }, LOADER_DURATION);
            } else {
              showErrorMsg("Payment failed");
              console.error("Payment status check failed");
            }
          };
  
          const payment = {
            sandbox: false,
            merchant_id: "235086",
            return_url: undefined, // Important
            cancel_url: undefined, // Important
            notify_url: "https://backoffice.alevelclothing.lk/api/v1/common/save-Payment/get-user-login",
            order_id: order_id,
            items: cartProductsData.map((data) => data.ProductName),
            amount: amount,
            currency: "LKR",
            hash: hash,
            first_name: loginUser.FirstName,
            last_name: loginUser.LastName,
            email: loginUser.EmailAddress,
            phone: loginUser.PhoneNo ? loginUser.PhoneNo : "",
            address: loginUser.AddressLineOne ? loginUser.AddressLineOne : "",
            city: "",
            country: loginUser.CountryName ? loginUser.CountryName : "",
            delivery_address: loginUser.AddressLineOne
              ? loginUser.AddressLineOne
              : "-",
            delivery_city: "",
            delivery_country: loginUser.CountryName
              ? loginUser.CountryName
              : "-",
            custom_1: "",
            custom_2: "",
          };
  
          window.payhere.startPayment(payment);
        } catch (error) {
          console.error("There was an error with the payment request:", error);
        }
      }
    }
  };
  

  const handleSpinClose = () => {
    setSpinOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Checkout</title>
        <meta name="description" content={siteTitle + " - Checkout"} />
        <meta name="keywords" content="Checkout"></meta>
      </Helmet>
      <SiteBreadcrumb title="Checkout" parent="Home" />

      <section className="section-big-py-space bg-light">
        <div className="custom-container">
          <div className="checkout-page contact-page">
            <div className="checkout-form">
              <Form onSubmit={handleCheckoutOnSubmit}>
                <Row>
                  <Col lg="6" sm="12" xs="12">
                    <div className="checkout-title">
                      <h3>
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Billing Details",
                              "lbl_check_billdetail"
                            )
                          : "Billing Details"}
                      </h3>
                    </div>
                    <div className="theme-form">
                      <Row className="check-out ">
                        <FormGroup className="col-md-6 col-sm-6 col-xs-12">
                          <Label className="field-label">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "First Name",
                                  "lbl_check_fname"
                                )
                              : "First Name"}
                          </Label>
                          <input
                            type="text"
                            name="FirstName"
                            className="form-control"
                            readOnly
                            value={loginUser.FirstName}
                          />
                        </FormGroup>

                        <FormGroup className="col-md-6 col-sm-6 col-xs-12">
                          <Label className="field-label">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Last Name",
                                  "lbl_check_lname"
                                )
                              : "Last Name"}
                          </Label>
                          <input
                            type="text"
                            name="LastName"
                            className="form-control"
                            readOnly
                            value={loginUser.LastName}
                          />
                        </FormGroup>
                        <FormGroup className="col-md-6 col-sm-6 col-xs-12">
                          <Label className="field-label">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Email Address",
                                  "lbl_check_email"
                                )
                              : "Email Address"}
                          </Label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            readOnly
                            value={loginUser.EmailAddress}
                          />
                        </FormGroup>
                        <FormGroup className="col-md-6 col-sm-6 col-xs-12">
                          <Label className="field-label">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Phone",
                                  "lbl_check_phone"
                                )
                              : "Phone"}
                          </Label>
                          <input
                            type="text"
                            name="phone"
                            className="form-control"
                            readOnly
                            value={loginUser.MobileNo}
                          />
                        </FormGroup>
                        <FormGroup className="col-12">
                          <Label className="field-label">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Country",
                                  "lbl_check_country"
                                )
                              : "Country"}
                          </Label>
                          <input
                            type="text"
                            name="CountryName"
                            className="form-control"
                            readOnly
                            value={loginUser.CountryName}
                          />
                        </FormGroup>
                        <FormGroup className="col-12">
                          <div>
                            <Label className="field-label">
                              {LocalizationLabelsArray.length > 0
                                ? replaceLoclizationLabel(
                                    LocalizationLabelsArray,
                                    "Delivery Address",
                                    "lbl_check_country"
                                  )
                                : "Delivery Address"}
                            </Label>
                            <div class="form-check">
                              <input
                                style={{ borderRadius: "50%" }}
                                class="form-check-input"
                                type="radio"
                                name="option"
                                checked={!useManualAddress}
                                id="option1"
                                value="option1"
                                onChange={() => setUseManualAddress(false)}
                              />
                              <label
                                style={{ marginLeft: "10px" }}
                                class="form-check-label"
                                for="option1"
                              >
                                Address
                              </label>
                              <br />
                              <span
                                style={{ marginLeft: "10px" }}
                                class="form-text text-light"
                              >
                                {loginUser.AddressLineOne}
                              </span>
                            </div>
                            {/* <div class="form-check">
                              <input
                                style={{ borderRadius: "50%" }}
                                class="form-check-input"
                                type="radio"
                                name="option"
                                id="option2"
                                value="option2"
                                onChange={() => setUseManualAddress(true)}
                              />
                              <label
                                style={{ marginLeft: "10px" }}
                                class="form-check-label"
                                for="option2"
                              >
                                Ship to a different address
                              </label>
                            </div> */}
                          </div>
                        </FormGroup>

                        {useManualAddress && (
                          <>
                            <FormGroup className="col-md-12 col-sm-12 col-xs-12">
                              <Label className="field-label">
                                Address
                              </Label>
                              <input
                                type="text"
                                name="address"
                                className="form-control"
                                value={manualAddress.address}
                                onChange={(e) =>
                                  setManualAddress({
                                    ...manualAddress,
                                    address: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                            <FormGroup className="col-md-6 col-sm-6 col-xs-12">
                              <Label className="field-label">City</Label>
                              <input
                                type="text"
                                name="city"
                                className="form-control"
                                value={manualAddress.city}
                                onChange={(e) =>
                                  setManualAddress({
                                    ...manualAddress,
                                    city: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                            <FormGroup className="col-md-6 col-sm-6 col-xs-12">
                              <Label className="field-label">
                                State / Province
                              </Label>
                              <input
                                type="text"
                                name="state"
                                className="form-control"
                                value={manualAddress.state}
                                onChange={(e) =>
                                  setManualAddress({
                                    ...manualAddress,
                                    state: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                            <FormGroup className="col-md-6 col-sm-6 col-xs-12">
                              <Label className="field-label">
                                Postcode / Zip
                              </Label>
                              <input
                                type="text"
                                name="zip"
                                className="form-control"
                                value={manualAddress.zip}
                                onChange={(e) =>
                                  setManualAddress({
                                    ...manualAddress,
                                    zip: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                            <FormGroup className="col-md-6 col-sm-6 col-xs-12">
                              <Label className="field-label">Country</Label>
                              <input
                                type="text"
                                name="country"
                                className="form-control"
                                value={manualAddress.country}
                                readOnly
                              />
                            </FormGroup>
                          </>
                        )}

                        <FormGroup className="col-md-12 col-sm-12 col-xs-12">
                          <Label className="field-label">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Order Note",
                                  "lbl_check_phone"
                                )
                              : "Order Note"}
                          </Label>

                          <textarea
                            name="OrderNote"
                            id="OrderNote"
                            cols="30"
                            rows="6"
                            placeholder="Order Notes"
                            className="form-control"
                            value={OrderNote}
                            onChange={(e) => setOrderNote(e.target.value)}
                          />
                        </FormGroup>
                      </Row>
                    </div>
                  </Col>
                  <Col lg="6" sm="12" xs="12">
                    <div className="checkout-details theme-form section-big-mt-space">
                      {cartProductsData != undefined &&
                      cartProductsData != null &&
                      cartProductsData.length > 0 ? (
                        <>
                          <div className="order-table table-responsive">
                            <table className="table table-bordered text-light">
                              <thead>
                                <tr>
                                  <th
                                    style={{ width: "25%" }}
                                    scope="col"
                                    id="lbl_hdr_check_prdname"
                                  >
                                    {LocalizationLabelsArray.length > 0
                                      ? replaceLoclizationLabel(
                                          LocalizationLabelsArray,
                                          "Product Name",
                                          "lbl_hdr_check_prdname"
                                        )
                                      : "Product Name"}
                                  </th>
                                  <th
                                    style={{ width: "25%" }}
                                    scope="col"
                                    id="lbl_hdr_check_price"
                                  >
                                    {LocalizationLabelsArray.length > 0
                                      ? replaceLoclizationLabel(
                                          LocalizationLabelsArray,
                                          "Price",
                                          "lbl_hdr_check_price"
                                        )
                                      : "Price"}
                                  </th>
                                  <th
                                    style={{ width: "25%" }}
                                    scope="col"
                                    id="lbl_hdr_check_qty"
                                  >
                                    {LocalizationLabelsArray.length > 0
                                      ? replaceLoclizationLabel(
                                          LocalizationLabelsArray,
                                          "Quantity",
                                          "lbl_hdr_check_qty"
                                        )
                                      : "Quantity"}
                                  </th>
                                  <th
                                    style={{ width: "25%" }}
                                    scope="col"
                                    id="lbl_hdr_check_total"
                                  >
                                    {LocalizationLabelsArray.length > 0
                                      ? replaceLoclizationLabel(
                                          LocalizationLabelsArray,
                                          "Total",
                                          "lbl_hdr_check_total"
                                        )
                                      : "Total"}
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {cartProductsData?.map((data, idx) => (
                                  <tr
                                    style={{ display: isMobile ? "flex" : "" }}
                                    key={idx}
                                  >
                                    <td
                                      style={{ width: "25%" }}
                                      className="product-name"
                                    >
                                      {makeProductShortDescription(
                                        data.ProductName,
                                        50
                                      )}
                                    </td>

                                    <td
                                      style={{ width: "25%" }}
                                      className="product-name"
                                    >
                                      <span className="unit-amount">
                                        {data.DiscountedPrice != undefined &&
                                        data.DiscountedPrice > 0 ? (
                                          <>
                                            <del style={{ color: "#9494b9" }}>
                                              {GetDefaultCurrencySymbol()} {" "}
                                              {makePriceRoundToTwoPlaces(
                                                data.Price
                                              )}
                                            </del>{" "}
                                            &nbsp; {GetDefaultCurrencySymbol()} {" "}
                                            {makePriceRoundToTwoPlaces(
                                              data.DiscountedPrice
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {GetDefaultCurrencySymbol()}{" "}
                                            {makePriceRoundToTwoPlaces(
                                              data.Price
                                            )}
                                          </>
                                        )}
                                      </span>
                                    </td>

                                    <td
                                      style={{ width: "25%" }}
                                      className="product-name"
                                    >
                                      {data.Quantity}
                                    </td>

                                    {(() => {
                                      let itemSubTotal =
                                        (data.DiscountedPrice != undefined &&
                                        data.DiscountedPrice > 0
                                          ? data.DiscountedPrice
                                          : data.Price) * (data.Quantity ?? 1);

                                      return (
                                        <td
                                          style={{ width: "25%" }}
                                          className="product-total"
                                        >
                                          <span className="subtotal-amount">
                                            {GetDefaultCurrencySymbol()}{" "}
                                            {makePriceRoundToTwoPlaces(
                                              itemSubTotal
                                            )}
                                          </span>
                                        </td>
                                      );
                                    })()}
                                  </tr>
                                ))}

                                <tr style={{ display: isMobile ? "flex" : "" }}>
                                  <td
                                    style={{ width: "50%" }}
                                    className="order-subtotal"
                                    colSpan={3}
                                  >
                                    <span
                                      id="lbl_check_cartsubtotal"
                                      className="count"
                                    >
                                      {LocalizationLabelsArray.length > 0
                                        ? replaceLoclizationLabel(
                                            LocalizationLabelsArray,
                                            "Cart Subtotal",
                                            "lbl_check_cartsubtotal"
                                          )
                                        : "Cart Subtotal"}
                                    </span>
                                  </td>

                                  <td
                                    style={{ width: "50%" }}
                                    className="order-subtotal-price"
                                  >
                                    <span className="order-subtotal-amount">
                                      {GetDefaultCurrencySymbol()}{" "}
                                      {makePriceRoundToTwoPlaces(CartSubTotal)}
                                    </span>
                                  </td>
                                </tr>

                                <tr style={{ display: isMobile ? "flex" : "" }}>
                                  <td
                                    style={{ width: "50%" }}
                                    className="order-shipping"
                                    colSpan={3}
                                  >
                                    <span id="lbl_check_shipping">
                                      {LocalizationLabelsArray.length > 0
                                        ? replaceLoclizationLabel(
                                            LocalizationLabelsArray,
                                            "Delivery Fee",
                                            "lbl_check_shipping"
                                          )
                                        : "Delivery Fee"}
                                    </span>
                                  </td>

                                  <td
                                    style={{ width: "50%" }}
                                    className="shipping-price"
                                  >
                                    <span>
                                      {/* {GetDefaultCurrencySymbol()}{" "}
                                      {makePriceRoundToTwoPlaces(
                                        ShippingSubTotal
                                      )} */}
                                      LKR 350.00
                                    </span>
                                  </td>
                                </tr>

                                <tr style={{ display: isMobile ? "flex" : "" }}>
                                  <td
                                    style={{ width: "50%" }}
                                    className="total-price"
                                    colSpan={3}
                                  >
                                    <span
                                      id="lbl_check_ordtotal"
                                      className="order-total-check-lbl text-light"
                                    >
                                      {LocalizationLabelsArray.length > 0
                                        ? replaceLoclizationLabel(
                                            LocalizationLabelsArray,
                                            "Order Total",
                                            "lbl_check_ordtotal"
                                          )
                                        : "Order Total"}
                                    </span>
                                  </td>

                                  <td
                                    style={{ width: "50%" }}
                                    className="product-subtotal"
                                  >
                                    <span className="order-total-check-val">
                                      {OrderTotalAfterDiscount != undefined &&
                                      OrderTotalAfterDiscount > 0 ? (
                                        <>
                                          <del>
                                            {GetDefaultCurrencySymbol()}{" "}
                                            {makePriceRoundToTwoPlaces(
                                              OrderTotal
                                            )}
                                          </del>
                                          &nbsp; &nbsp;{" "}
                                          {GetDefaultCurrencySymbol()}{" "}
                                          {makePriceRoundToTwoPlaces(
                                            OrderTotalAfterDiscount
                                          )}
                                        </>
                                      ) : (
                                        `${GetDefaultCurrencySymbol()} ${" "} ${makePriceRoundToTwoPlaces(
                                          OrderTotal
                                        )}`
                                      )}
                                    </span>
                                  </td>
                                </tr>
                                {loginUser.IsSpinDiscountClaimed &&
                                PaymentMethod == 5 &&
                                (loginUser.DiscountName === "Buy 1 Get 1" ||
                                  loginUser.DiscountName === "Buy 2 Get 1" ||
                                  loginUser.DiscountName === "Buy 3 Get 1") ? (
                                  <tr
                                    style={{ display: isMobile ? "flex" : "" }}
                                  >
                                    <td
                                      style={{ width: "50%" }}
                                      className="total-price"
                                      colSpan={3}
                                    >
                                      <span
                                        id="lbl_check_ordtotal"
                                        className="order-total-check-lbl text-light"
                                      >
                                        {LocalizationLabelsArray.length > 0
                                          ? replaceLoclizationLabel(
                                              LocalizationLabelsArray,
                                              "Discount from Spin",
                                              "lbl_check_ordtotal"
                                            )
                                          : "Discount from Spin"}
                                        <br />
                                      </span>
                                      <div
                                        style={{ display: "flex", gap: "10px" }}
                                      >
                                        {IsDoubled === null ||
                                        IsDoubled === "false" ? (
                                          <div
                                            style={{
                                              width: "160px",
                                              marginLeft: 3,
                                              background: "green",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              cursor: "pointer",
                                              height: "30px",
                                              color: "#fff",
                                              padding: 0,
                                              fontSize: "12px",
                                            }}
                                            className="btn"
                                            onClick={() =>
                                              ShareWithWhatsapp(
                                                loginUser.DiscountName
                                              )
                                            }
                                          >
                                            Double Your Discount{" "}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        {count > 0 && count <= Greaterthan() ? (
                                          <Typography
                                            sx={{ color: "yellow", mb: 1 }}
                                          >
                                            Remaining Shares - {CountDown()}
                                          </Typography>
                                        ) : null}{" "}
                                      </div>
                                    </td>

                                    <td
                                      style={{ width: "50%" }}
                                      className="product-subtotal"
                                    >
                                      <span
                                        style={{ color: "green" }}
                                        className="order-total-check-val"
                                      >
                                        Get 01 Free
                                      </span>
                                      <p>
                                        You will receive one free T-shirt with
                                        your discount.
                                      </p>
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}

                                {loginUser.IsSpinDiscountClaimed &&
                                PaymentMethod == 5 &&
                                loginUser.DiscountName != "Buy 1 Get 1" &&
                                loginUser.DiscountName != "Buy 2 Get 1" &&
                                loginUser.DiscountName != "Buy 3 Get 1" ? (
                                  <tr
                                    style={{ display: isMobile ? "flex" : "" }}
                                  >
                                    <td
                                      style={{ width: "50%" }}
                                      className="total-price"
                                      colSpan={3}
                                    >
                                      <span
                                        id="lbl_check_ordtotal"
                                        className="order-total-check-lbl text-light"
                                      >
                                        {LocalizationLabelsArray.length > 0
                                          ? replaceLoclizationLabel(
                                              LocalizationLabelsArray,
                                              "Discount from Spin",
                                              "lbl_check_ordtotal"
                                            )
                                          : "Discount from Spin"}
                                      </span>
                                      <div
                                        style={{ display: "flex", gap: "10px" }}
                                      >
                                        {IsDoubled === null ||
                                        IsDoubled === "false" ? (
                                          <div
                                            style={{
                                              width: "160px",
                                              marginLeft: 3,
                                              background: "green",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              cursor: "pointer",
                                              height: "30px",
                                              color: "#fff",
                                              padding: 0,
                                              fontSize: "12px",
                                            }}
                                            className="btn"
                                            onClick={() =>
                                              ShareWithWhatsapp(
                                                loginUser.DiscountName
                                              )
                                            }
                                          >
                                            Double Your Discount
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        {count > 0 && count <= Greaterthan() ? (
                                          <Typography
                                            sx={{ color: "yellow", mb: 1 }}
                                          >
                                            Remaining Shares - {CountDown()}
                                          </Typography>
                                        ) : null}{" "}
                                      </div>
                                    </td>

                                    <td
                                      style={{ width: "50%" }}
                                      className="product-subtotal"
                                    >
                                      <span
                                        style={{ color: "green" }}
                                        className="order-total-check-val"
                                      >
                                        {GetDefaultCurrencySymbol()}{" "}
                                        {calDiscount()}
                                      </span>
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                                {loginUser.IsSpinDiscountClaimed &&
                                PaymentMethod == 5 &&
                                loginUser.DiscountName != "Buy 1 Get 1" &&
                                loginUser.DiscountName != "Buy 2 Get 1" &&
                                loginUser.DiscountName != "Buy 3 Get 1" ? (
                                  <tr
                                    style={{ display: isMobile ? "flex" : "" }}
                                  >
                                    <td
                                      style={{ width: "50%" }}
                                      className="total-price"
                                      colSpan={3}
                                    >
                                      <span
                                        id="lbl_check_ordtotal"
                                        className="order-total-check-lbl text-light"
                                      >
                                        {LocalizationLabelsArray.length > 0
                                          ? replaceLoclizationLabel(
                                              LocalizationLabelsArray,
                                              "Due Amount",
                                              "lbl_check_ordtotal"
                                            )
                                          : "Due Amount"}
                                      </span>
                                    </td>

                                    <td
                                      style={{ width: "50%" }}
                                      className="product-subtotal"
                                    >
                                      <span
                                        style={{ color: "green" }}
                                        className="order-total-check-val"
                                      >
                                        {GetDefaultCurrencySymbol()}{" "}
                                        {(OrderTotal - calDiscount()).toFixed(
                                          2
                                        )}
                                      </span>
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="payment-box">
                        <div className="upper-box">
                          <div className="payment-options">
                            <ul>
                              <li>
                                <div className="radio-option">
                                  <input
                                    type="radio"
                                    name="payment-group"
                                    id="payment-credit-card"
                                    defaultChecked={true}
                                    onClick={(e) => handleSpinOpen()}
                                  />
                                  <label htmlFor="payment-credit-card">
                                    Credit Card or Debit Card
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div style={style}></div>
                              </li>
                              <li>
                                <p style={{ color: "gray" }}>
                                  To complete the transaction, you will be
                                  directed to the secure payment gateway,
                                  PayHere.
                                </p>
                              </li>
                              <li>
                                <div className="radio-option">
                                  <input
                                    type="radio"
                                    name="payment-group"
                                    id="payment-cash"
                                    onClick={(e) => handleCashonDelivery()}
                                  />
                                  <label htmlFor="payment-cash">
                                    Cash on delivery
                                  </label>
                                </div>
                              </li>
                              <li>
                                <p style={{ color: "gray" }}>
                                  Pay the courier in cash upon delivery.
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>

                        {cartProductsData != undefined &&
                          cartProductsData != null &&
                          cartProductsData.length > 0 && (
                            <div className="text-right">
                              <button
                                type="submit"
                                className="btn-normal updated btn"
                              >
                                Place Order
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </section>

      <BestFacilities />

      <CustomModal
        isOpen={isModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        message="Do you really want to place the order?"
      />

      {/*Stripe card section starts here */}
      {showCardSectionStripe == true ? (
        <Modal
          isOpen={showCardSectionStripe}
          toggle={HandleStripCardModal}
          centered={true}
          size="lg"
          className="theme-modal"
          id="exampleModal"
          role="dialog"
          aria-hidden="true"
        >
          <ModalBody className="modal-content">
            <Button
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => {
                e.preventDefault();
                HandleStripCardModal();
              }}
            >
              <span aria-hidden="true">×</span>
            </Button>
            <div className="news-latter">
              <div className="modal-bg">
                <div className="offer-content">
                  <div>
                    <h2>Card Details!</h2>
                    <p>
                      Provide your card detail
                      <br /> and confirm final order!
                    </p>

                    <Elements stripe={stripePromise}>
                      <CheckoutStripForm
                        UserID={loginUser.UserID}
                        OrderNote={OrderNote}
                        cartJsonData={JSON.stringify(cartItemsSession)}
                        ShippingSubTotal={ShippingSubTotal}
                        OrderTotal={OrderTotal}
                        OrderTotalAfterDiscount={OrderTotalAfterDiscount}
                        CouponCode={CouponCode}
                        HandleStripCardModal={HandleStripCardModal}
                        PlaceAndConfirmCustomerOrder={
                          PlaceAndConfirmCustomerOrder
                        }
                      />
                    </Elements>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      ) : (
        <></>
      )}

      {/* Stripe card section ends here */}

      {/* Paypal card section starts here */}

      {showCardSectionPaypal == true ? (
        <PayPalScriptProvider
          options={{
            "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
          }}
        >
          <Modal
            isOpen={showCardSectionPaypal}
            toggle={HandlePaypalCardModal}
            centered={true}
            size="lg"
            className="theme-modal"
            id="exampleModal"
            role="dialog"
            aria-hidden="true"
          >
            <ModalBody className="modal-content">
              <Button
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  e.preventDefault();
                  HandlePaypalCardModal();
                }}
              >
                <span aria-hidden="true">×</span>
              </Button>
              <div className="news-latter">
                <div className="modal-bg">
                  <div className="offer-content">
                    <div>
                      <h2>PayPal Options!</h2>

                      <PayPalButtons
                        style={{ layout: "vertical" }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </PayPalScriptProvider>
      ) : (
        <></>
      )}

      {/* Paypal card section ends here */}

      {spinOpen == true &&
      !loginUser.IsSpinDiscountClaimed &&
      PaymentMethod == 5 ? (
        <Modal
          isOpen={spinOpen}
          centered={true}
          size="lg"
          className="theme-modal"
          id="exampleModal"
          role="dialog"
          aria-hidden="true"
        >
          <ModalBody className="modal-content">
            <div style={{ padding: "10px", background: "#191919" }}>
              <Button
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  e.preventDefault();
                  handleSpinClose();
                }}
              >
                <span aria-hidden="true">×</span>
              </Button>
            </div>
            <div style={{ padding: "10px", background: "#191919" }}>
              <Spinner cartItems={cartItemsSession} total={OrderTotal} />
            </div>
          </ModalBody>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default Checkout;
