import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Config from "../../../helpers/Config";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import { Media, Row, Col, Container } from "reactstrap";
import aboutImg from "../../../resources/custom/images/about-us.jpg";
import CustomerTestimonial from "../../components/shared/CustomerTestimonial";

const About = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);

  return (
    <>
      <Helmet>
        <title>{siteTitle} - About Us</title>
        <meta name="description" content={siteTitle + " - About us page"} />
        <meta name="keywords" content="About us"></meta>
      </Helmet>

      <SiteBreadcrumb title="About-Us" parent="Home" />

      <section className="about-page section-big-py-space">
        <div className="custom-container">
          <Row>
            <Col lg="6">
              <div className="banner-section">
                <Media src={aboutImg} className="img-fluid w-100" alt="" />
              </div>
            </Col>
            <Col lg="6">
              <h3>About our Store</h3>
              <p style={{color: '#fff'}} className="mb-2">
                {" "}
                Welcome to <b>A LEVEL</b>, Sri Lanka's premier online
                destination for high-quality, affordable T-shirts. We cater to
                young Sri Lankans, primarily students, who seek stylish and
                durable clothing without straining their finances. Our mission
                is to alleviate the financial burden on students, allowing them
                to focus on their education.
              </p>
              <p style={{color: '#fff'}} className="mb-2">
                {" "}
                At <b>A LEVEL</b>, we pride ourselves on our comprehensive
                manufacturing capabilities. From fabric knitting, dyeing, and
                finishing to the final garment production, everything is done
                in-house. This vertical integration enables us to maintain
                strict quality control while keeping our prices competitive. Our
                commitment to quality and durability ensures that every product
                meets the highest standards before reaching our customers.
              </p>
              <p style={{color: '#fff'}} className="mb-2">
                {" "}
                Our social responsibility efforts are at the core of our
                business. We reinvest a substantial portion of our profits into
                funding higher education for students in rural and low-income
                communities. This initiative reflects our dedication to giving
                back and supporting the future of Sri Lanka.
              </p>
              <p style={{color: '#fff'}}>
                {" "}
                Founded on passion and a vision for positive change, <b>A LEVEL</b> is
                proud to manufacture all our products locally. This approach not
                only supports the Sri Lankan economy but also generates new job
                opportunities, helping to reduce unemployment. By choosing A
                LEVEL, you are not only purchasing exceptional clothing but also
                contributing to a brighter future for Sri Lanka.
              </p>
            </Col>
          </Row>
        </div>
      </section>

      {/* <CustomerTestimonial /> */}
    </>
  );
};

export default About;
