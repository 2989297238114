import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { Row, Col, Media } from "reactstrap";
import Config from "../../../helpers/Config";
import { getFileExtensionNameFromPath } from "../../../helpers/ConversionHelper";
import ReactImageMagnify from 'react-image-magnify';

const ProductDetailImages = (props) => {
    const slider1 = useRef(Slider);
    const slider2 = useRef(Slider);
    const [state, setState] = useState({ nav1: null, nav2: null });
    const [imagesList, setImagesList] = useState([]);
    const [adminPanelBaseURL, setadminPanelBaseURL] = useState(Config['ADMIN_BASE_URL']);

    useEffect(() => {
        setState({
            nav1: slider1.current,
            nav2: slider2.current,
        });
    }, []);
    const { nav1, nav2 } = state;

    const MakeImageList = () => {
        let arrayData = [];
        if (props.ProductImages && props.ProductImages.length > 0) {
            for (let i = 0; i < props.ProductImages.length; i++) {
                arrayData.push({
                    __typename: getFileExtensionNameFromPath(),
                    alt: "product detail image " + i,
                    src: props.ProductImages[i].AttachmentURL,
                });
            }
        }
        setImagesList(arrayData);
    }

    useEffect(() => {
        MakeImageList();
    }, [props.ProductImages]);

    return (
        <>
            <Slider className="product-slick" asNavFor={nav2} ref={(slider) => (slider1.current = slider)}>
                {imagesList &&
                    imagesList.map((img, i) => {
                        return (
                            <div key={i}>
                                <ReactImageMagnify {...{
                                    smallImage: {
                                        alt: img.alt,
                                        isFluidWidth: true,
                                        src: img.src,
                                    },
                                    largeImage: {
                                        src: img.src,
                                        width: 1200,
                                        height: 1800
                                    },
                                    enlargedImagePosition: 'over', // This can be 'beside' or 'over'
                                    enlargedImageContainerDimensions: {
                                        width: '200%',
                                        height: '200%'
                                    },
                                    className: "img-fluid image_zoom_cls-0"
                                }} />
                            </div>
                        );
                    })}
            </Slider>
            <Row>
                <Col>
                    <Slider className="slider-nav" asNavFor={nav1} ref={(slider) => (slider2.current = slider)} slidesToShow={3} swipeToSlide={true} focusOnSelect={true} arrows={false} adaptiveHeight={true}>
                        {imagesList &&
                            imagesList.map((img, i) => {
                                return (
                                    <div key={i}>
                                        <Media src={img.src} alt={img.alt} className="img-fluid image_zoom_cls-0" />
                                    </div>
                                );
                            })}
                    </Slider>
                </Col>
            </Row>
        </>
    );
}

export default ProductDetailImages;
